import { map } from 'rxjs/operators';
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { RestServicesService } from "src/app/services/rest-services.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonUtility } from 'src/app/utility/common.utility';
import { UtcDatePipe } from 'src/app/pipes/custom.pipe';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: "app-split-terminal",
  templateUrl: "./split-terminal.component.html",
  styleUrls: ["./split-terminal.component.scss"],
  providers: [UtcDatePipe],
})
export class SplitTerminalComponent implements OnInit {
  @ViewChild('DataSection',{static:true}) DataSection:ElementRef
  dynamicFormGroup: FormGroup;
  response: any = [];
  command: any = {
    command: "account/global/_default",
    entityTypeId: "global",
  };
  backTickRegex = /`/g;
  selectRegex = /_select/g;
  resetResponse: boolean = false;
  maxDate = new Date();
  minDate = new Date();
  submitted: boolean = false;

  constructor(
    private restService: RestServicesService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    protected commonUtilityService: CommonUtility,
    private utcDate: UtcDatePipe,
    protected customeSnakbar: CustomSnakbarComponent,
    private spinner:NgxSpinnerService
  ) {}
  ngOnInit(): void {
    this.createDynamicFormGroup();
    this.route.queryParams.subscribe((params) => {
      if (params["email"]) {
        const payload = {
          command: "account/PA/_party_search_result",
          entityTypeId: "global:",
          parameters: {
            email: params["email"],
          },
        };
        this.command = payload;
        this.executeCommand();
      } else {
        this.executeCommand();
      }
    });
  }

  createDynamicFormGroup() {
    this.dynamicFormGroup = this.fb.group({
      command: [null],
      entityTypeId: [null],
    });
  }

  executeCommand() {
    const dis = this;
    this.spinner.show();
    this.restService
      .scriptQuery(this.command)
      .pipe(
        map((res: any) => {
          const formatedData = [];
          res.body.sections.forEach(function (sec, si) {
            formatedData.push({ section: sec.section, data: [], form: null });

            if (sec.section === "DATA") {
              formatedData[si]["data"] = sec.data;
            } else if (sec.section === "COPY") {
              formatedData[si]["data"] = sec.data;
            } else if (sec.section === "SELECT") {
              if (sec.data) {
                formatedData[si]["data"] = sec.data;
              }
            } else if (sec.section === "FORM") {
              if (sec.data) {
                formatedData[si]["data"] = sec.data;
              }
              if (sec.form) {
                formatedData[si]["form"] = sec.form;
                dis.dynamicFormGroup
                  .get("command")
                  .patchValue(sec.form.command);
                dis.dynamicFormGroup
                  .get("entityTypeId")
                  .patchValue(sec.form.entityTypeId);
                sec.form.parameters.forEach(function (formCtrl, i) {
                  if (formCtrl.required) {
                    dis.dynamicFormGroup.addControl(
                      formCtrl.field,
                      dis.fb.control("", [Validators.required])
                    );
                  } else {
                    dis.dynamicFormGroup.addControl(
                      formCtrl.field,
                      dis.fb.control(null)
                    );
                  }
                  if (formCtrl.value) {
                    if (formCtrl.fieldType.toLowerCase() === "date") {
                      dis.dynamicFormGroup
                        .get(formCtrl.field)
                        .patchValue(new Date(formCtrl.value));
                    } else {
                      dis.dynamicFormGroup
                        .get(formCtrl.field)
                        .patchValue(formCtrl.value);
                    }
                  }
                });
              }
            } else {
              sec.data.forEach(function (secData: string) {
                const commands = dis.findStringWithBackTick(secData);
                const verbiages = secData
                  .split("`")
                  .filter(
                    (part, index) => index % 2 === 0 && part.trim() !== ""
                  );
                formatedData[si]["data"].push({
                  commands: commands
                    .map((c) => c.split("|"))
                    .filter((f) => f !== ""),
                  verbiages: verbiages,
                });
              });
            }
          });
          return formatedData;
        })
      )
      .subscribe((data: any) => {
        this.spinner.hide();
        const dis = this;

        if(data.find((dt) => dt.section === 'DATA')){
          (this.DataSection.nativeElement as HTMLElement).scrollTop = 0;
        }

        if (this.response.length <= 0 || this.resetResponse) {
          if(this.resetResponse){
            this.response.forEach((res, i) => {
              if (data.find((dt) => dt.section === res.section)) {
                dis.response[i] = data[i];
              } else {
                if (res.section !== "COPY") {
                  dis.response[i] = {};
                }
              }
            });
          }else{
            this.response = data;
          }
         this.resetResponse = false;
        } else {
          data.forEach(function (v) {
            if (dis.response.find((resp) => resp.section === v.section)) {
              const i = dis.response.findIndex(
                (res) => res.section === v.section
              );

              if (v.section === "COPY") {
                dis.response.push(v);
              } else {
                dis.response[i] = v;
              }
            } else {
              dis.response.push(v);
            }
          });
        }
      });
  }

  findStringWithBackTick(text: string): any {
    const regex = /`([^`]*)`/g;

    let matches;
    const result = [];

    while ((matches = regex.exec(text)) !== null) {
      result.push(matches[1]);
    }
    return result;
  }

  callApi(
    command: any,
    entityType: any,
    section?: string,
    strData?: string,
    label?: string
  ) {
    if (label && label.toLowerCase() === "remove") {
      const copySectionSFiltersArr = this.response.filter(
        (res) => res.section === "COPY"
      );
      const copySectionFitered = copySectionSFiltersArr.find((v) =>
        v.data.find((e) => e === strData)
      );
      const index = this.response.findIndex((s) => s === copySectionFitered);
      this.response.splice(index, 1);
    } else {
      this.command = {
        command: command,
        entityTypeId: entityType,
      };
      this.executeCommand();
    }
  }

  submit(event) {
    const dis = this;
    this.submitted = true;
    if (this.dynamicFormGroup.invalid) {
      this.dynamicFormGroup.updateValueAndValidity();
      return false;
    }

    if (event) {
      this.dynamicFormGroup.get("command").patchValue(event.submitter.value);
    }

    const formValue = this.dynamicFormGroup.getRawValue();
    let parameters = {};
    this.response.forEach(function (res) {
      if (res.section === "FORM") {
        res.form.parameters.forEach(function (formCtrl) {
          if (formCtrl.fieldType.toLowerCase() === "date") {
            parameters[formCtrl.field] = formValue[formCtrl.field]
              ? dis.utcDate.transform(formValue[formCtrl.field])
              : "";
          }else if(formCtrl.fieldType.toLowerCase() === "copy"){
            parameters[formCtrl.field] = dis.patchValueInCopyField();
          } else {
            parameters[formCtrl.field] = formValue[formCtrl.field]
              ? formValue[formCtrl.field].toString()
              : "";
          }
        });
      }
    });
    const payload = {
      command: formValue.command,
      entityTypeId: formValue.entityTypeId,
      parameters,
    };
    this.command = payload;
    this.executeCommand();
  }

  checkTab(val: string) {
    return val.includes("\t");
  }
  checkAtRate(val: string) {
    return val.includes("@@@");
  }

  checkBackTick(val: string) {
    return val.includes("`");
  }

  executeInitialQuery() {
    const initPayload = {
      command: "account/global/_default",
      entityTypeId: "global",
    };
    this.command = initPayload;
    this.resetResponse = true;
    this.executeCommand();
  }

  clearCopySection() {
    this.response = this.response.filter((v) => v.section !== "COPY");
  }

  checkCopySection(): boolean {
    return this.response.filter((v) => v.section === "COPY").length > 0;
  }

  copyToClipBoard(data: any) {
    const dis = this;
    let modifiedData = [];
    if (data && data.length) {
      data.forEach((v, i) => {
        if (dis.checkTab(v) && dis.checkBackTick(v)) {
          const splitedData = v.split("\t");
          const filteredData =  splitedData.filter((u)=>!this.checkBackTick(u));
          modifiedData.push(filteredData.join());
        }
      });
    }
    return modifiedData.join('\r\n');
  }

  patchValueInCopyField():string{
    if(this.checkCopySection()){
      const entityTypeArry = [];
      this.response.filter((f)=>f.section === 'COPY').map((v)=>{
        entityTypeArry.push(v.data[0].split('\t')[0].split('|')[1]);
      })
      return entityTypeArry.join(',');
    }else{
      return '';
    }
  }


}
